import * as qiniu from "qiniu-js";
import { getUploadToken, qiniuDomain } from './api.js'

export default {
  data() {
    return { }
  },
  methods: {
    // 上传文件到七牛云
    qiniuUpload(file) {
      return new Promise((resolve, reject) => {
        this.$http.get(getUploadToken).then(res => {
          // console.log("upload 》》》 ", res.data);
          if(res && res.data && res.data.length>0) {
            let uptoken = res.data;
            let key = new Date().getTime() + '.' + file.name + '.png'; //这是上传后返回的文件名，为了避免重复加上了时间戳，也可以再加随机数如 Math.random(1000)
            let config = {
              useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
              region: qiniu.region.z0, //选择上传域名区域；当为 null 或 undefined 时，自动分析上传域名区域。华东区
              domain: qiniuDomain, //配置好的七牛云域名
              chunkSize: 100, //每个分片的大小，单位mb，默认值3
              forceDirect: true //直传还是断点续传方式，true为直传
            };
            let putExtra = {
              fname: file.name, //文件原始文件名
              params: {},
              mimeType: [] || null
              // ...config,
            };
            let observable = qiniu.upload(file, key, uptoken, putExtra, config);
            observable.subscribe({
              next: result => {
                //主要用来展示进度
                // console.log(result);
              },
              error: err => {
                //上传错误后触发
                // console.log(err);
                reject(err);
              },
              complete: result => {
                //上传成功后触发。包含文件地址。
                // console.log(result);
                resolve({qiniuDomain, result});
              }
            })
          } else {
            this.$toast('上传失败，请稍后重试');
          }
        })
        // .catch(error => {
        //   console.error('upload 》》》数据获取失败 》》》 ', error);
        //   resolve(false);
        // });
      })
    },
    // 复制链接
    copyUrl(linkURL) {
      this.global.copyText(linkURL).then(res => {
        if(res){
          // console.log('###复制成功###');
          this.$toast('海报链接复制成功');
        } else {
          // console.log('###复制失败###');
          this.$toast('海报链接复制失败，请稍后重试');
        }
      }).catch(err => {
        // console.log('复制失败');
        this.$toast('当前浏览器不支持复制本链接');
      })
    },
  },
}